import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep12Decimal, keep2Decimal, keep4Decimal, keepNumber } from '@assets/js/regExUtil';

const formatterPurchaseStart = val => {
  switch (val) {
    case 0:
      return '未生成';
    case 1:
      return '部分生成';
    case 2:
      return '全部生成';
  }
};

export const tableProperties = [
  {
    label: '收票状态',
    prop: 'invoicingStatus',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '120px',
    visible: true,
    formatter: val => (val === 0 ? '未收票' : '已收票')
  },
  {
    label: '采购发票情况',
    prop: 'purchase_start',
    itemType: 'select',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '部分生成' },
      { value: 2, label: '全部生成' }
    ],
    formatter: val => formatterPurchaseStart(val),
    input: true,
    sortable: false,
    widthAuto: true
  },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true, visible: false },
  { label: '通知单号', prop: 'requisition_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '通知日期',
    prop: 'notification_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  // { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  {
    label: '出运发票号',
    prop: 'tran_nos',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '进仓日期',
    prop: 'entry_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '应开票金额', prop: 'invoice_price', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '录入人', prop: 'stff_name', itemType: 'input', widthAuto: true, sortable: false },
  {
    label: '录入时间',
    prop: 'create_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '备注', prop: 'remark', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true },
  store.state.status
];
export const tableProperties2 = [
  {
    label: '其他采购发票情况',
    prop: 'purchase_start',
    itemType: 'select',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '部分生成' },
      { value: 2, label: '全部生成' }
    ],
    formatter: val => formatterPurchaseStart(val),
    input: true,
    sortable: false,
    labelWidth: 160
  },
  { label: '通知单号', prop: 'requisition_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '通知日期',
    prop: 'notification_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '采购合同号',
    prop: 'order_no',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '进仓日期',
    prop: 'entry_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '应开票金额', prop: 'invoice_price', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '录入人', prop: 'stff_name', itemType: 'input', widthAuto: true, sortable: false },
  {
    label: '录入时间',
    prop: 'create_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '备注', prop: 'remark', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true },
  store.state.status
];
export const requisitionMainFormProperties = [
  {
    label: '收票状态',
    prop: 'invoicingStatus',
    itemType: 'select',
    model: '',
    options: [
      { label: '未收票', value: 0 },
      { label: '已收票', value: 1 }
    ],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '出运发票号',
    prop: 'tran_nos',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '开票数量',
    prop: 'invoice_num',
    itemType: 'input',
    model: '',
    size: 'small',
    input: (list, index, val) => (list[index].model = keepNumber(val)),
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '应开票金额',
    prop: 'invoice_price',
    itemType: 'input',
    model: '',
    size: 'small',
    input: (list, index, val) => (list[index].model = keep2Decimal(val)),
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '公司抬头',
    prop: 'cptt_id',
    itemType: 'select',
    model: '',
    options: [],
    filterable: true,
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '进仓日期',
    prop: 'entry_date',
    model: '',
    itemType: 'date',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '采购发票情况',
    prop: 'purchase_start',
    itemType: 'select',
    model: '',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '部分生成' },
      { value: 2, label: '全部生成' }
    ],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '通知日期',
    prop: 'notification_time',
    itemType: 'date',
    model: '',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: false
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 3,
    split: 24,
    subWidth: 80,
    disabled: false,
    show: true
  }
];
export const requisitionMainFormProperties2 = [
  {
    label: '采购合同号',
    prop: 'order_no',
    itemType: 'select',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '应开票金额',
    prop: 'invoice_price',
    itemType: 'input',
    model: '',
    size: 'small',
    input: (list, index, val) => (list[index].model = keep2Decimal(val)),
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '公司抬头',
    prop: 'cptt_id',
    itemType: 'select',
    model: '',
    options: [],
    filterable: true,
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '开票供应商',
    prop: 'supp_abbr',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '总开票数量',
    prop: 'invoice_num',
    itemType: 'input',
    model: '',
    size: 'small',
    input: (list, index, val) => (list[index].model = keepNumber(val)),
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '进仓日期',
    prop: 'entry_date',
    model: '',
    itemType: 'date',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '通知日期',
    prop: 'notification_time',
    itemType: 'date',
    model: '',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '其他采购发票情况',
    prop: 'purchase_start',
    itemType: 'select',
    model: '',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '部分生成' },
      { value: 2, label: '全部生成' }
    ],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 3,
    split: 24,
    subWidth: 80,
    disabled: false,
    show: true
  }
];
export const requisitionSubTableProperties = [
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '供应商联系人',
    prop: 'supp_contact',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '联系方式',
    prop: 'supp_phone',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      disabled: row => !row.isSplit
    }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '报关中文品名',
    prop: 'prod_hsname',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      disabled: true
    }
  },
  {
    label: '开票品名',
    prop: 'invoice_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'input', maxLength: 20 }
  },
  {
    label: '海关编码',
    prop: 'prod_hscode',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.prod_num = keep2Decimal(val))
    }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'select', options: [] }
  },
  {
    label: '开票单价(￥)',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.prod_price = keep12Decimal(val))
    }
  },
  {
    label: '开票金额(￥)',
    prop: 'invoice_money',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 20
    }
  },
  {
    label: '税率(%)',
    prop: 'tax_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.tax_rate = keep2Decimal(val))
    }
  },
  {
    label: '税额(￥)',
    prop: 'tax_accrued',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 9
    }
  },
  {
    label: '退税率(%)',
    prop: 'tax_refundrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.tax_refundrate = keep2Decimal(val))
    }
  },
  {
    label: '退税额(￥)',
    prop: 'tax_refund',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 9
    }
  },
  {
    label: '总毛重(KG)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.prod_tgweight = keep2Decimal(val))
    }
  },
  {
    label: '总净重(KG)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.prod_tnweight = keep2Decimal(val))
    }
  },
  {
    label: '报关单价($)',
    prop: 'prod_dusd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '报关金额($)',
    prop: 'prod_money',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.prod_money = keep4Decimal(val))
    }
  }
];
export const requisitionSubTableProperties3 = [
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      disabled: row => !row.isSplit
    }
  },
  {
    label: '包材品类',
    prop: 'mtrb_pctype',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '材料名称',
    prop: 'prod_hsname',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      disabled: false
    }
  },
  {
    label: '开票品名',
    prop: 'invoice_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'input', maxLength: 20 }
  },
  {
    label: '开票数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.prod_num = keep2Decimal(val))
    }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: false, type: 'select', options: [] }
  },
  {
    label: '开票单价(￥)',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.prod_price = keep12Decimal(val))
    }
  },
  {
    label: '开票金额(￥)',
    prop: 'invoice_money',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.invoice_money = keep12Decimal(val))
    }
  },
  {
    label: '费用金额',
    prop: 'modr_fetotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.prod_price = keep12Decimal(val))
    }
  },
  {
    label: '开票金额(含费用)',
    prop: 'invoice_moneyfee',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '总毛重(KG)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.prod_tgweight = keep2Decimal(val))
    }
  },
  {
    label: '总净重(KG)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.prod_tnweight = keep2Decimal(val))
    }
  },
  {
    label: '供应商联系人',
    prop: 'supp_contact',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '联系方式',
    prop: 'supp_phone',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '税率(%)',
    prop: 'tax_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.tax_rate = keep2Decimal(val))
    }
  },
  {
    label: '税额(￥)',
    prop: 'tax_accrued',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 9
    }
  },
  {
    label: '退税率(%)',
    prop: 'tax_refundrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.tax_refundrate = keep2Decimal(val))
    }
  },
  {
    label: '退税额(￥)',
    prop: 'tax_refund',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 9
    }
  },
  {
    label: '报关单价($)',
    prop: 'prod_dusd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '报关金额($)',
    prop: 'prod_money',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: true,
      type: 'input',
      maxLength: 13,
      input: (val, row) => (row.prod_money = keep4Decimal(val))
    }
  }
];
export const requisitionSubTableProperties2 = [
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票类目',
    prop: 'invoice_name_type',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'select',
      options: [
        { value: '宠物用品', label: '宠物用品' },
        { value: '玩具', label: '玩具' },
        { value: '塑料制品', label: '塑料制品' }
      ]
    }
  },
  {
    label: '开票品名',
    prop: 'invoice_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },

  {
    label: '外包装数量',
    prop: 'prod_qpc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '箱数',
    prop: 'prod_box_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '开票数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票单价(￥)',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '开票金额',
    prop: 'invoice_money',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '部件单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '生产日期',
    prop: 'part_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    formatter: val => getDateNoTime(val, false)
  }
];
export const invoiceDetailsForAuxiliaryMaterialsTableProperties = [
  // {
  //   label: '收票状态',
  //   prop: 'invoicingStatus',
  //   itemType: 'select',
  //   input: true,
  //   options: [
  //     { value: 0, label: '未收票' },
  //     { value: 1, label: '已收票' }
  //   ],
  //   sortable: false,
  //   subItem: {
  //     disabled: false,
  //     type: 'newSelect',
  //     options: {
  //       0: { value: 0, label: '未收票' },
  //       1: { value: 1, label: '已收票' }
  //     }
  //   }
  // },
  {
    label: '开票通知时间',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '采购发票情况',
    prop: 'requisition_part_type',
    itemType: 'select',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '全部生成' }
    ],
    input: true,
    sortable: true,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '未生成' },
        1: { value: 1, label: '全部生成' }
      }
    }
  },
  {
    label: '采购发票生成时间',
    prop: 'pinv_create_time',
    itemType: 'input',
    input: false,
    sortable: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '录入人',
    prop: 'stff_name',
    itemType: 'input',
    input: true,
    sortable: true
  },
  {
    label: '出运发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: true,
    sortable: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: true,
    sortable: true
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: true,
    sortable: true
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: true,
    sortable: true
  },
  {
    label: '材料名称',
    prop: 'prod_hsname',
    itemType: 'input',
    input: true,
    sortable: false
  },
  {
    label: '包材品类',
    prop: 'mtrb_pctype',
    otherProp: 'mtrb_pctype_list',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    multiple: true
  },
  {
    label: '开票品名',
    prop: 'invoice_name',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '开票数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '开票单价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '开票金额',
    prop: 'invoice_money',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '费用金额',
    prop: 'modr_fetotal',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '开票金额(含费用)',
    prop: 'invoice_moneyfee',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '总毛重(KG)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '总净重(KG)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: true
  },
  {
    label: '公司抬头',
    prop: 'cptt_name',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false
  }
];
