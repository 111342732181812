import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const requisitionAPI = {
  getRequisitions: params => getNoCatch(`${topic.requisitionTopic}/getRequisition`, params),
  getRequisitionMtrb: params => getNoCatch(`${topic.requisitionTopic}/getRequisitionMtrb`, params),
  deleteRequisition: params => getNoCatch(`${topic.requisitionTopic}/deleteRequisitionById`, params),
  getRequisitionById: params => getNoCatch(`${topic.requisitionTopic}/getRequisitionById`, params),
  editRequisition: params => postNoCatch(`${topic.requisitionTopic}/editRequisitionById`, params),
  addRequisitionById: params => postNoCatch(`${topic.requisitionTopic}/addRequisitionById`, params),
  includesModrFees: params => getNoCatch(`${topic.requisitionTopic}/includesModrFees`, params),
  editRequisitionMtrb: params => postNoCatch(`${topic.requisitionTopic}/editRequisitionMtrb`, params),
  addRequisitionMtrb: params => postNoCatch(`${topic.requisitionTopic}/addRequisitionMtrb`, params)
};
